<template>
  <v-select
    solo
    prepend-inner-icon="mdi-book-multiple-outline"
    :items="$helpers.showPage()"
    item-text="text"
    item-value="value"
    v-model="$store.state[store].count"
    v-on:input="searchCount($event)"
  />
</template>

<script>
export default {
  props: ["store", "action"],
  methods: {
    searchCount(event) {
      var vm = this;
      vm.$store.dispatch(`${vm.store}/${vm.action}`, {
        page: 0,
        query: vm.$store.state[vm.store].query,
        date: vm.$store.state[vm.store].date,
        count: event,
        status: vm.$store.state[vm.store].status,
      });
    },
  },
};
</script>